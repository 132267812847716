.highlight-container {
	width: 100%;
	margin: 3rem auto;

	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: inherit;

		h3 {
			font-size: 2rem;
		}
	}

	.highlights {

		&::after {
			content: "";
			display: block;
			background: linear-gradient(
				270.21deg,
				rgb(9, 22, 41) 10%,
				rgba(9, 22, 41, 0) 99.82%
			);
			width: 5%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
		}
		position: relative;
		margin-top: 2rem;
		display: flex !important;
		max-width: 95%;
		margin-left: auto;
		flex-direction: row;
		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: calc(50% - 6.5rem);
			left: -5rem;
			z-index: 6;
			transition: all 0.3s linear;
			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;
				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}
			svg {
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: 2rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}
		.highlight {
			margin-right: 2rem;
			height: 100%;
			img {
				width: calc(100% - 2rem);
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
@media (max-width: $mobile) {
	.highlight-container {
		.highlights {
			.arrow-slideshow {
				top: calc(50% - 4.5rem);

				&.left {
					left: 1rem;
					right: unset;
				}
			}
		}
	}
}