.security {
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-top: 2rem;
		line-height: 2rem;
	}
	form {
		width: 50%;
		.input {
			&.error::before {
				content: " ";
				display: block;
				position: absolute;
				left: -1.5rem;
				height: 100%;
				width: 0.3rem;
				border-radius: 0.2rem;
				background-color: $red;

				animation: wiggle 0.1s linear 4;
			}
		}
		.error {
			grid-column: 1/3;
			margin-bottom: 2rem;
			.validator {
				margin-top: 2rem;
				display: flex;
				div {
					&:first-of-type {
						svg {
							margin-left: 0;
						}
					}
					svg {
						vertical-align: middle;
						fill: $red;
						width: 1.2rem;
						margin: 0 1rem 0 3rem;

						&.validate {
							fill: $green;
						}
					}
				}
			}
		}
	}
}
@media (max-width: $mobile) {
	.security {
		form {
			width: 100%;
			.error .validator {
				display: block;
				div svg {
					margin: 0 1rem 0 0;
				}
			}

		}
	}
}