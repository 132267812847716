.notifications {
	.newsletter {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 5%;
		h4 {
			margin-top: auto;
			margin-bottom: auto;
		}
		div {
			display: flex;
			justify-content: space-between;
		}
	}
}
@media (max-width: $mobile) {
	.notifications {
		width: auto;
	}
}