.playlist-videos {
	width: 100%;
	margin: 3rem auto;

	.titles {
		width: 90%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		a {
			color: $white;
			text-decoration: none;
		}
		h3 {
			text-transform: inherit;
			font-weight: bold;
			font-size: 1.8rem;
			margin: auto 0;
		}

		h5 {
			position: relative;
			text-transform: capitalize;
			cursor: pointer;
			font-size: 1.6rem;
			transition: transform ease 300ms;
			svg {
				vertical-align: middle;
				margin-left: 5px;
				transition: transform ease 300ms;
			}
			&:hover {
				transform: translate(-25px ,0);
				svg{
					transform: translate(35px ,0);
				}
			}
		}

		.count {
			background-color: $red;
			border-radius: 2rem;
			padding: 0.3rem 1rem;
			margin-left: 1rem;
			font-size: 12px;
			font-weight: 600;
		};
	}

	.videos-container {
		margin-top: 2rem;
		margin-right: auto;
		margin-left: auto;
		max-width: 90%;
		position: relative;

		.arrow-slideshow {
			background-color: #36598d;
			border-radius: 2rem;
			padding: 2rem 1.2rem;
			border: none;
			position: absolute;
			transform: translateY(-50%) scale(100%);
			top: 50%;
			left: -5rem;
			z-index: 6;
			transition: all 0.3s linear;

			&:hover {
				transform: translateY(-50%) scale(102%);
				transition: all 0.3s linear;
				background-color: #4168a8;

				svg {
					transition: all 0.3s linear;
					transform: scale(98%);
				}
			}

			svg {
				fill: $white;
				width: 1.5rem;
			}
			&.right {
				left: unset;
				right: -5rem;
			}
			&.disabled {
				svg {
					opacity: 0.3;
				}
			}
		}
		.slick-track {
			margin-left: 0;
		}

		.slick-slide {
			margin: 0 30px 0 0;
		}

		.slick-list {
			margin: 0 -30px 0 0;
		}
		.video {
			cursor: pointer;
			position: relative;
			width: 100%;
			margin-right: 3rem;

			> .cross {
				position: absolute;
				top: 10px;
				left: 10px;
				opacity: 0;
				transition: all ease 0.5s;
				z-index: 9;
				span {
					vertical-align: text-top;
					padding-left: 4px;
					opacity: 0;
					font-size: 11px;
					transition: all ease 0.5s;
				}
				&:hover {
					path {
						stroke: #C03F42 !important;
					}
					span {
						opacity: 1 !important;
					}
				}
			}

			.payable {
				width: 20%;
				position: absolute;
				top: 5px;
				right: 5px;
			}

			a {
				color: white;
				text-decoration: none;

				&:before {
					//filter color
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					opacity: 0;
					background: rgba(31, 26, 86, 0.58);
					transition: all ease 0.5s;
				}

				> .image {
					display: block;
					height: auto;
					width: 100%;
					border-radius: 5px 5px 0 0;
					padding-bottom: 90px;
					&.event {
						margin-bottom: 75px;
					}
				}


				.title-container {
					background-color: #191549;
					position: absolute;
					bottom: -2px;
					text-transform: capitalize;
					font-size: 1.8rem;
					padding: 0.8rem 1rem 2rem;
					height: 70px;
					left: 0;
					right: 0;
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
					transition: all ease 0.5s;

					.play {
						position: absolute;
						width: 5rem;
						height: 5rem;
						top: -2.5rem;
						right: 2rem;
						opacity: 0;

						.play_button {
							height: 100%;
							width: 100%;
							cursor: pointer;
							.play_sign, .round {
								transition: all ease-in-out .5s;
							}
							&:hover {
								.round {
									fill: #ffffff;
								}
								.play_sign {
									fill: #362CAE;
								}
							}
						}
					}

					.video-date {
						display: grid;
						grid-template-columns: 33.33% 33.33% 33.33%;
						font-size: 10px;
						line-height: 12px;
						padding-bottom: 5px;

						.line {
							svg{
								width: 100%;
								vertical-align: middle;
							}
						}

						.date {
							text-align: center;
						}
					}
					h4 {
						font-size: 14px;
						line-height: 17px;
					}

					.video-description {
						margin-top: 0.5rem;
						font-size: 1rem;
						line-height: 1.4rem;
						height: 0;
						overflow: hidden;
						transition: height ease 0.5s;
					}
				}
			}

			&:hover {
				a:before {
					opacity: 1 !important;
				}
				.video-description {
					height: auto !important;
				}
				.cross {
					opacity: 1 !important;
				}
				.play {
					opacity: 1 !important;
				}
				.title-container {
					height: 40% !important;
				}
			}
		}
	}
	&.events {
		.video {
			height: 230px;
			a {

				> .image {
					border-radius: 5px 5px 0 0;
					&.event {
						margin-bottom: 75px;
					}
				}
			}

		}
	}
}

@media (max-width: $mobile) {
	.playlist-videos {
		&.events {
			.video {
				height: 185px !important;
			}
		}
		.videos-container {
			.video {

				a {
					.title-container {
						padding: .4rem .5rem 1rem;
						height: 75px;
						h4 {
							font-size: 12px;
						}
					}
				}

			}
			.arrow-slideshow {
				top: 50%;

				&.left {
					left: 1rem;
					right: unset;
				}
				&.right {
					left: unset;
					right: 1rem;
				}
			}
		}
	}
}
