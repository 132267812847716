.parameters-container {
	font-size: 1.6rem;
	margin-bottom: 10rem;
	.parameters-title {
		margin: auto auto 5rem;
		position: relative;
		&:before {
			content: '';
			background: linear-gradient(180deg, rgba(10, 0, 51, 0.26) 0%, rgba(10, 0, 51, 0) 100%);
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		.title {
			padding: 15rem 8rem 5rem;
			position: relative;
			display: flex;
			h1 {
				a {
					&:before {
						content: url("../../images/button_round.svg");
						display: block;
						width: 40px;
						height: 40px;
						margin-right: 20px;
						&:hover {
							cursor: pointer;
						}
					}
				}
				display: flex;
				z-index: 10;
				font-weight: 600;
				font-size: 4rem;
			}
		}
		svg {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}

	.parameters-content {
		transition: all $fade linear;
		display: flex;
		width: 90%;
		margin: auto;
		position: relative;
		nav {
			width: 20%;
			ul {
				border-radius: 8px;
				li {
					padding: 18px 28px 18px;
					cursor: pointer;
					font-size: 16px;
					border-bottom: 1px solid hsla(0, 0%, 100%, .2);
					&.selected {
						background: hsla(0, 0%, 100%, .2);
					}

					&:first-child {
						border-radius: 8px 8px 0 0;
					}
					&:last-child {
						border-radius: 0 0 8px 8px ;
					}
					svg {
						fill: $white;
						vertical-align: middle;
						margin-right: 1.5rem;
					}
					span {
						margin: auto 0;
					}
				}
			}
		}
	}

	.block {
		padding: 2rem 3rem 2rem 3rem;
		margin: 0 2rem;
		width: 72%;
		background: rgba(255, 255, 255, 0.06);
		border-radius: 8px;
		.title {
			text-transform: capitalize;
			font-weight: bolder;
			border-bottom: 1px solid hsla(0, 0%, 100%, .2);
			padding-bottom: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			h3 {
				font-size: 1.8rem;
			}
			.edit {
				width: 10%;
				height: 5%;
				font-weight: 400;
				cursor: pointer;
				padding: 1rem;
				border-radius: 8px;
				text-align: center;
				border: 1px solid hsla(0, 0%, 100%, .2);
			}
		}

		article {
			padding-top: 2rem;
		}

		&.account {
			.grid {
				display: grid;
				grid-template-areas: "article avatar"
				"delete  avatar";
				grid-template-columns: 1fr 1fr;
				gap: 1rem 4rem;
			}

			article {
				grid-area: article;
			}
			#avatar {
				grid-area: avatar;
			}
			#delete {
				grid-area: delete;
			}
		}

		input[type="submit"],
		#delete button {
			height: 50px;
			background: #362CAE;
			border: none;
			border-radius: 8px;
			color: $white;
			padding: 1rem 2rem;
		}

		#delete {
			.button-block {
				button:first-child {
					margin-right: 5px;
				}
				button:last-child {
					margin-left: 5px;
				}
			}
			button {
				background: transparent;
				border: 1px solid hsla(0, 0%, 100%, .2);
			}
		}
		label, #avatar h4 {
			padding-bottom: 1rem;
			font-size: 1.2rem;
			text-transform: uppercase;
			font-weight: 600;
			color: rgba(255, 255, 255, 0.5);
		}
	}

	.input {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		margin-bottom: 2rem;

		input {
			-webkit-appearance: none;
			display: flex;
			width: 100%;
			min-width: 25rem;
			height: 2.4rem;
			position: relative;
			padding: 1.3rem 2.2rem;
			background: rgba(255, 255, 255, 0.12);
			border: none;
			border-radius: 5px;
			color: $white;
			font-family: $fontRegular;
			&::placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
		}

		&.error::before {
			content: " ";
			display: block;
			position: absolute;
			left: -1.5rem;
			height: 100%;
			width: 0.3rem;
			border-radius: 0.2rem;
			background-color: $red;

			animation: wiggle 0.1s linear 4;
		}
	}

	#validUpdate {
		display: none;
		vertical-align: middle;
		padding: 1rem;
		border-radius: 1rem;
		text-align: center;
		transition: all 0.1s linear;
		transform: translateY(-50%) translateX(0);
		position: relative;
		font-size: 1.6rem;
		margin-top: 3rem;
		animation: wiggleSmall 0.1s 4;
		&.error {
			opacity: 1;
			border: 1px solid $red;
		}
		&.display {
			display: block;
			border: 1px solid $green;
		}
	}

		#validUpdate1 {
		display: none;
		vertical-align: middle;
		padding: 1rem;
		border-radius: 1rem;
		text-align: center;
		transition: all 0.1s linear;
		transform: translateY(-50%) translateX(0);
		position: relative;
		font-size: 1.6rem;
		margin-top: 7rem;
		animation: wiggleSmall 0.1s 4;
		width:	50%;
		&.error {
			opacity: 1;
			border: 1px solid $red;
		}
		&.display {
			display: block;
			border: 1px solid $green;
		}
	}

	@import "./avatar";
	@import "./preferencesParam";
	@import "./securityParam";
	@import "./notifParam";
	@import "./languages";
	@import "./subscriptionParam";
}


@media (max-width: $mobile) {
	.parameters-container {
		.absolute-center{
			background: #F9FAFB;
			border-radius: 5px;
		}
			#defLabel {
				margin-top: auto;
				margin-bottom: auto;
			}
			#brand {
				width: 7rem;
			}
		
		.mb-2 {
			color: #667085;
		}
		.StripeElement {
			display: block;
			margin: 10px 0 5px 0;
			max-width: 500px;
			padding: 10px 14px;
			border-radius: 4px;
			background: rgba(255, 255, 255, 0.12);
			border: 1px solid #c3c2c2;
		}

		.method {
			margin-top: 1rem;
			width: 100%;
			border: 1px solid #6a62d6;
			border-radius: 5px;
			display: flex;
			justify-content: space-between;
		}

		.number {
			margin: auto 0;
		}
		margin: auto auto 3rem !important;
		.parameters-title {
			margin: auto auto 1rem;
			position: relative;
			&:before {
				content: '';
				background: linear-gradient(180deg, rgba(10, 0, 51, 0.26) 0%, rgba(10, 0, 51, 0) 100%);
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
			.title {
				padding: 8rem 1rem 1rem !important;
				h1 {
					font-size: 5vw !important;
					line-height: 40px;
					display: flex;
					z-index: 10;
					font-weight: 600;
					span {
						cursor: pointer;
						z-index: 99;
						&:before {
							content: url("../../images/button_round.svg");
							display: block;
							width: 40px;
							height: 40px;
							margin-right: 20px;
							&:hover {
								cursor: pointer;
							}
						}
					}
				}
			}
			svg {
				display: none;
			}
		}
		.content {
			padding: 2.5rem;
			#avatar {
				margin-top: 0;
			}
			.summary{
				display: flex;
    			flex-direction: column;
    			justify-content: space-around;
				padding: 1.5rem;
				min-height: 17rem;
				margin-top: 1rem;
				background-color: white;
				color: #667085;
				.submit {
					input[type="submit"] {
						height: 50px;
						background: #362CAE;
						border: none;
						border-radius: 8px;
						color: #fff;
						padding: 1rem 2rem;
					}
				}
			}
			.active-plan {
				padding: 1.5rem;
				display: flex;
    			height: 12rem;
    			flex-direction: column;
    			justify-content: space-around;
				margin-top: 1rem;
				background-color: white;
				color: #667085;

				h2, .price {
					font-weight: 600;
					font-size: 1.8rem;
				}
				.price {
					color: #6a62d6;
				}
				.badge {
					font-size: small;
					background-color: #6a62d691;
					border-radius: 5px;
					padding: 1rem;
					color: white;
				}
			}
			.plans {
				h2, .price {
					font-weight: 600;
					font-size: 1.8rem;
				}
				.price {
					color: #6a62d6;
				}
			}
			.invoices{
				> div {
					display: flex;
					justify-content: space-around;
					align-items: center;
					min-height: 4.8rem;
					padding-left: .5rem;
					padding-right: .5rem;
					background-color: white;
					color: #667085;
					span {
						margin-right: 1rem;
						margin-left: 1rem;
					}
					&:nth-child(even) {
						background-color: #EEF4FF;
					}
					.download {
						cursor: pointer;
					}
				}
				table {
					thead {
						border-bottom: 1px solid #cfcfcf;
					}
					td, th {
						padding: 1rem;
						text-align: left;
						svg {
							width: 20px;
							height: 20px;
						}
						label {
							padding: 0;
						}
					}
					tbody {
						tr:nth-child(even) {
							background-color: #EEF4FF;
						}
					}
					.download {
						cursor: pointer;
					}
				}
			}
			h4 {
				padding-bottom: 1rem;
				font-size: 1.2rem;
				text-transform: uppercase;
				font-weight: 600;
				color: rgba(255, 255, 255, 0.5);
			}
			.select-block {
				.select {
					width: 100%;
					height: 3rem;
				}
			}
			.block {
				background: unset;
				padding: 0;
				width: 100%;
				margin: 0;
				.title {
					display: none;
				}
				.grid {
					grid-template-columns: 1fr;
					grid-template-areas: "avatar"
					"article"
					"delete";
					gap: unset;
				}
				input[type="submit"] {
					width: 100%;
					background: #4F49A1;
					border-radius: 4rem;
				}
			}
			#delete {
				margin-top: 2rem;
			}

		}
	}
}
