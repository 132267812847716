footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 7rem 8rem 5rem;
	background: #393387;
	box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
	backdrop-filter: blur(42px);
	position: relative;

	> svg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
	.logo-section {
		.socials {
			margin-top: 24px;
			svg {
				margin: 0 2.5px;
			}
		}
		.logo {
			width: auto;
			max-height: 60px;
		}
		
		.powered-by {
			margin-top: 2rem;
			text-align: left;
			font-size: 1rem;
			img {
				height: 3.5rem;
				width: auto;
			}
		}
			.copyrights {
				font-size: 1rem;
				text-align: left;
				span {
					display: block;
					margin-bottom: 0.3rem;
				a {
					color: $white;
					text-decoration: none;
				}
			}
		}	  
	}
	.discover {
		display: flex;
		justify-content: space-evenly;
		text-align: left;
	}
	.login {
		li {
			width: 180px;
			svg {
				fill: $white;
				float: right;
			}
		}
	}
	section {
		h4 {
			font-weight: bold;
			margin-bottom: 2rem;
		}
		ul {
			li {
				margin-bottom: 2.2rem;
				text-transform: capitalize;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
@media (max-width: $mobile) {
	footer {
		grid-template-areas: "discover"
		"login-block";
		.discover {
			grid-area: discover;
			justify-content: space-between;
			margin: 3rem;
		}
		.login-block {
			grid-area: login-block;
			margin: 0 3rem;
		}
		.socials {
			display: flex;
			justify-content: space-between;
		}
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		text-align: center;
		padding: 2rem 0;
		.logo-section {
			margin: 3rem;
			position: relative;
				.powered-by
				{
					img 
					{
						height: 40px;
   						width: auto;
					}
				}
			
			&:before {
				content: "";
				border-top: 1px solid grey;
				position: absolute;
				top: -3.5rem;
				left: 0;
				right: 0;
			}
			img {
				width: 100%;
			}
		}
	}
}
