.info-page {
	padding-top: 16rem;
	padding-bottom: 4rem;

	.d-flex {
		position: relative;
	}
	.back {
		cursor: pointer;
		z-index: 8;
		&:before {
			position: absolute;
			top: 2rem;
			left: 2rem;
			content: url("../images/button_round.svg");
			display: block;
			width: 40px;
			height: 40px;
			margin-right: 20px;
			z-index: 99;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.info-data {
		background-color: #393387;
		padding: 6rem 5rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.14);
		position: relative;
		.image-block {
			grid-area: imageBlock;
			float: left;
			margin-right: 20px;
			img {
				width: 100%;
			}
		}
		h2 {
			grid-area: title;
			font-weight: bolder;
			font-size: 3rem;
			text-transform: capitalize;
			width: 100%;
		}
		.views {
			grid-area: views;
			margin-top: 2rem;
			width: 100%;
		}
		.description {
			grid-area: description;
			margin-top: 2rem;
			font-size: 1.8rem;
			line-height: 2.5rem;
			width: 100%;
		}
	}
}

.policy-page {
	margin: 15rem 6rem 4rem;
	.policy-text{
		h2{
			font-size: 26px;
			font-weight: 800;
			margin: 2rem 0;
		}
		h3{
			font-size: 18px;
			font-weight: 700;
			margin: 1.5rem 0;
		}
		p {
			u {
				font-weight: 600;
			}
		}
	}
}

@media (max-width: $mobile) {
	.info-page {
		padding-top: 7rem;
		width: 100%;
		.info-data {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-areas: "title" "imageBlock" "views" "description";
			.image-block {
				margin-right: unset;
			}
			h2 {
				margin: 2rem 0;
			}
		}

		.back:before {
			top: 2rem;
			left: 2rem;
			z-index: 14;
		}
	}
}
