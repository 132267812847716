.main-header {
    text-transform: capitalize;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.2rem 6rem 0;
    position: absolute;
    width: calc(100% - 12rem);
    z-index: 7;

    .mobile-menu,
    .mobile-close {
        display: none;
    }

    .hide-background {
        &.hover {
            visibility: visible;
            opacity: 0.3;
            transition: all $fade;
        }

        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(7, 17, 32, 0.92);
        visibility: hidden;
        opacity: 0;
        transition: all $fade;
    }

    .logo {
        a {
            display: block;
            text-decoration: none;
        }

        img {
            width: auto;
            max-height: 60px;
        }
    }

    @media (min-width: $mobile + 1) {
        nav {
            @include center(true, true);

            > ul {
                display: flex;
                justify-content: space-around;

                li.menu {
                    display: none;
                }

                > li {
                    margin-top: auto;
                    margin-bottom: auto;
                    cursor: pointer;

                    a {
                        display: flex;
                        color: $white;
                        text-decoration: none;
                        padding: 0.8rem 2.2rem;
                    }

                    a:before {
                        margin-right: 10px;
                    }

                    &:nth-of-type(1) {
                        a:before {
                            content: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.59472 15.0257V12.5722C5.59471 11.9505 6.12688 11.4454 6.78605 11.4415H9.20641C9.86853 11.4415 10.4053 11.9477 10.4053 12.5722V15.0186C10.4053 15.5578 10.8665 15.9961 11.4383 16H13.0895C13.8607 16.0019 14.6011 15.7142 15.1471 15.2006C15.6931 14.6869 16 13.9894 16 13.262V6.29268C16 5.70511 15.7238 5.14777 15.246 4.7708L9.63619 0.539415C8.65559 -0.200702 7.25505 -0.176793 6.30349 0.596307L0.814326 4.7708C0.313887 5.13666 0.0147809 5.69565 0 6.29268V13.2549C0 14.771 1.30306 16 2.91046 16H4.52403C4.7993 16.0019 5.06399 15.9001 5.25935 15.7171C5.4547 15.5342 5.56457 15.2853 5.56456 15.0257H5.59472Z' fill='white'/></svg>");
                        }
                    }

                    &:nth-of-type(2) {
                        a:before {
                            content: url("data:image/svg+xml,<svg width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.03 16'><rect width='6.77' height='6.83' rx='2.03' ry='2.03' fill='white'/><path d='M4.73,9.17H2a2,2,0,0,0-2,2.05V14A2,2,0,0,0,2,16H4.73a2,2,0,0,0,2-2.05V11.22A2,2,0,0,0,4.73,9.17Z' transform='translate(0.01 0)' fill='white'/><path d='M14,9.17h-2.7a2,2,0,0,0-2,2.05V14a2,2,0,0,0,2,2.05H14A2,2,0,0,0,16,14V11.22A2,2,0,0,0,14,9.17Z' transform='translate(0.01 0)' fill='white'/><rect x='9.2' width='6.83' height='6.83' rx='2.14' ry='2.14' fill='white'/></svg>");
                        }
                    }

                    &:nth-of-type(3) {
                        a:before {
                            content: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12.7286 8.81416L1.42357 15.8599C0.982635 16.1347 0.406742 15.9929 0.137279 15.5432C0.0475027 15.3934 0 15.2212 0 15.0456V0.954235C0 0.427225 0.418914 0 0.935669 0C1.10784 0 1.27667 0.0484453 1.42357 0.140003L12.7286 7.1857C13.1695 7.46051 13.3085 8.04783 13.0391 8.49752C12.9617 8.62669 12.8552 8.73523 12.7286 8.81416Z' fill='white'/></svg>");
                        }
                    }

                    a.active {
                        border-bottom: 2px solid $red;
                    }
                }
            }
        }
    }

    .account-section {
        display: flex;

        .search-input, .search-text {
            background-color: #94B2FF;
            color: $white;
            border-radius: 20rem;
            border: none;
            padding: 0 20px;
            position: absolute;
            right: 3rem;
            height: 4.2rem;
            line-height: 4.2rem;
            top: -1rem;
        }

        .search-container {
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(7, 17, 32, 0.96);
            width: 100%;
            height: 100%;
            min-height: 100vh;
            z-index: 100;
            isolation: isolate !important;

            .search-section {
                display: flex;
                align-items: center;

                width: 100%;
                margin-bottom: -3rem;

                h4 {
                    display: none;
                }

                .button-submit {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    width: auto;
                    padding: 1rem 2rem 1rem 2rem;
                    border-radius: 0 4rem 4rem 0;
                    color: $white;
                    font-size: 2.2rem;
                    cursor: pointer;
                    border-left: 1px solid #3e4551;
                }

                .close {
                    margin: auto 8rem;
                    width: 2rem;
                    height: 2rem;
                    background: radial-gradient(
                                    100% 100% at 0% 0%,
                                    rgba(255, 255, 255, 0.28) 0%,
                                    rgba(255, 255, 255, 0) 100%
                    );
                    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                    backdrop-filter: blur(20px);
                    cursor: pointer;
                    border-radius: 50%;
                    padding: 1rem;
                    border: 1px solid rgba(255, 255, 255, 0.34);

                    svg {
                        fill: white;
                        width: 1.5rem;
                        position: relative;
                        top: -0.2rem;
                        left: 0.125rem;
                        padding-right: 3px;
                        padding-left: 1px;
                    }
                }

                form {
                    width: 100%;
                    display: flex;
                    position: relative;
                    left: 10rem;

                    span {
                        width: 50%;
                        margin: 6rem auto;
                        display: flex;
                        height: fit-content;
                        position: relative;

                        input {
                            width: 100%;
                            padding: 1rem 6rem 1rem 6rem;
                            background: radial-gradient(
                                            100% 100% at 0% 0%,
                                            rgba(255, 255, 255, 0.41) 0%,
                                            rgba(255, 255, 255, 0) 100%
                            );
                            box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                            border-radius: 4rem;
                            color: $white;
                            border: 1.5px solid #3e4551;
                            font-size: 2rem;
                        }

                        svg {
                            width: 3rem;
                            bottom: 1rem;
                            left: 2rem;
                            fill: #fff;
                            position: absolute;
                            z-index: 9;
                        }
                    }
                }
            }
        }

        > div {
            position: relative;
            margin: auto 1rem;

            &.notifications.hover:before {
                content: "";
                display: block;
                position: absolute;
                left: 74%;
                top: 4rem;
                background-color: #4F49A1;
                border: 1px solid rgba(255, 255, 255, 0.34);
                box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                width: 2rem;
                height: 2rem;
                border-radius: 5px 0;
                transform: translateX(-50%) rotate(225deg);
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            }

            &.search-button,
            &.notifications {
                width: 2rem;
                height: 2rem;
                cursor: pointer;
                position: relative;
            }

            &.languages {
                position: relative;
                cursor: default;
                text-transform: capitalize;

                @include icons("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+"
                );

                img {
                    width: 2rem;
                    height: unset;
                }

                &:hover ul {
                    visibility: visible;
                    opacity: 1;
                }

                ul {
                    visibility: hidden;
                    opacity: 0;
                    background-color: $navigation;
                    border: 1px solid rgba(255, 255, 255, 0.34);
                    border-radius: 1rem;
                    position: absolute;
                    top: calc(100% + 3rem);
                    right: -2rem;
                    width: 12rem;
                    padding: 0.7rem;
                    z-index: 2;
                    li {
                        padding: 1rem 0.5rem;
                        cursor: pointer;
                        border-radius: 0.5rem;

                        span {
                            margin-left: 0.5rem;
                        }

                        &:hover {
                            background: rgba(255, 255, 255, 0.14);
                        }
                    }

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 74%;
                        top: -1.1rem;
                        background-color: $navigation;
                        border: 1px solid rgba(255, 255, 255, 0.34);
                        box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                        width: 2rem;
                        height: 2rem;
                        border-radius: 5px 0;
                        transform: translateX(-50%) rotate(225deg);
                        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                    }

                    &::before {
                        content: "";
                        z-index: -1;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: -45px;
                        bottom: 0;
                    }
                }
            }

            svg {
                fill: $white;
            }

            .notifications-number {
                position: absolute;
                bottom: 12px;
                right: -0.6rem;
                background-color: #d32b2b;
                padding: 0.2rem;
                border-radius: 50%;
                height: 1.2rem;
                width: 1.2rem;

                span {
                    @include center(true, true);
                    font-size: 13px;
                }
            }

            &.account {
                span {
                    margin-left: 1rem;
                    cursor: pointer;
                }

                img {
                    cursor: pointer;
                    width: 4rem;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                }

                .buttons {
                    a:not(.not-button) {
                        text-decoration: none;

                        background-color: transparent;
                        font-family: $fontRegular;
                        color: $white;
                        border: 1.5px solid $white;
                        padding: 1rem;
                        border-radius: 2rem;

                        &#register-button {
                            color: $blue;
                            background-color: $white;
                            margin-right: 1.5rem;
                        }
                    }
                }

                .account-menu {
                    @include icons(
                                    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+"
                    );
                    position: relative;

                    .sub-menu {
                        position: absolute;
                        top: calc(100% + 2rem);
                        visibility: hidden;
                        opacity: 0;
                        transition: all $fade;
                        width: 20rem;
                        right: 0;

                        &.hover {
                            visibility: visible;
                            opacity: 1;
                            transition: all $fade;
                        }

                        ul {
                            border: 1px solid rgba(255, 255, 255, 0.34);
                            position: relative;
                            width: 100%;
                            box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                            border-radius: 5px;
                            padding: 20px;

                            li {
                                display: flex;
                                cursor: pointer;
                                list-style: none;
                                padding: 1rem;
                                border-bottom: 1px solid #8d8d8d;
                                background: rgba(255, 255, 255, 0.14);

                                &:first-of-type {
                                    display: none;
                                }

                                &:nth-of-type(2) {
                                    border-radius: 5px 5px 0 0;
                                }

                                &:last-of-type {
                                    border-radius: 0 0 5px 5px;
                                    border-bottom: none;
                                }

                                a {
                                    height: 100%;
                                    width: 100%;
                                    display: inline-flex;
                                }
                                span {
                                    position: relative;
                                    top: 0.8rem;
                                }

                                svg {
                                    height: 100%;
                                    padding: 0.5rem;
                                    vertical-align: middle;
                                }
                            }

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 74%;
                                top: -1.1rem;
                                background-color: inherit;
                                border: 1px solid rgba(255, 255, 255, 0.34);
                                box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
                                width: 2rem;
                                height: 2rem;
                                border-radius: 5px 0;
                                transform: translateX(-50%) rotate(225deg);
                                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                            }
                        }
                    }
                }
            }

        }
    }
}

.notifications.sub-menu {
    &.hover {
        display: block;
    }

    display: none;
    position: absolute;
    z-index: 9999;
    top: 8.7rem;
    right: 15rem;
    padding: 20px;
    min-width: 310px;
    max-width: 310px;

    border: 1px solid rgba(255, 255, 255, 0.34);
    background-color: #4F49A1;
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border-radius: 5px;

    .item-rows {
        overflow-y: auto;
        max-height: 330px;
        padding-top: 5px;
        padding-left: 15px;
        margin-left: -15px;
        padding-right: 15px;
        margin-right: -15px;
    }

    .title {
        justify-content: space-between;
        display: flex;
        padding-bottom: 5px;

        span:hover {
            cursor: pointer;
        }
    }

    .content {
        position: relative;

        .item {
            text-decoration: none;
            color: white;
            display: flex;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            cursor: pointer;
            list-style: none;
            padding: 1rem;
            margin: 1rem 0;

            .image {
                float: left;
                width: 17%;
                display: flex;
                padding: 5px;
                position: relative;

                img {
                    margin: auto;
                    width: 100%;
                }

                span {
                    position: absolute;
                    bottom: 10px;
                    padding: 4px;
                    font-size: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    border-radius: 10px;

                    &.live {
                        background-color: red;
                        border: 0.5px solid red;
                    }

                    &.off {
                        background: grey;
                        border: 0.5px solid white;
                    }
                }
            }

            .message {
                padding-left: 5px;
                float: left;
                width: 75%;

                .message-text {
                    padding: 5px 0;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.3px;
                }

                .date {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.408px;
                    opacity: 0.7;
                }
            }
        }

        &.new {
            &:before {
                content: "";
                display: block;
                position: absolute;
                border-radius: 1rem;
                width: 1.5rem;
                height: 1.5rem;
                background: red;
                right: -5px;
                top: -5px;
            }
        }
    }
}

@media (max-width: $mobile) {

    .main-header {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 7rem;

        > .logo {
            padding: 1rem 0 1rem 1rem;
        }

        .border {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }

        .mobile-menu {
            display: block;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            margin: 2rem;
        }

        .search-section {
            justify-content: center;

            .close {
                position: relative;
                top: 0;
                left: -3rem;
                margin: auto 0 !important;
            }

            form {
                position: relative;
                top: 0;
                left: 0 !important;

                span {
                    width: 70% !important;

                    input {
                        justify-content: center;
                        height: 2rem;
                        width: 15rem;
                    }

                    .button-submit {
                        position: absolute;
                        right: unset !important;
                        top: 8rem !important;
                        bottom: 0 !important;
                        border-radius: 4rem !important;
                        color: $white !important;
                        font-size: 2rem !important;
                        line-height: 2rem;
                        height: 2rem !important;
                        background: radial-gradient(
                                        100% 100% at 80% -20%,
                                        rgba(255, 255, 255, 0.28) 0%,
                                        rgba(255, 255, 255, 0) 100%
                        ) !important;
                        border: 1.5px solid #3e4551;

                    }
                }
            }

        }

        nav {
            display: none;
        }

        &.menu-open {
            isolation: isolate;
            z-index: 15;
            position: relative;

            .logo {
                display: none;
            }

            .mobile-close {
                z-index: 100;
                display: block;
                position: absolute;
                top: 1.5rem;
                right: 0;
                margin: 1rem;
                cursor: pointer;
                height: 3rem;
                width: 3rem;

            }

            nav {
                overflow-x: auto;
                display: block;
                z-index: 4;
                box-sizing: border-box;
                width: 100vw;
                position: fixed;
                left: 0;
                top: 0;
                height: 100vh;

                ul {
                    margin-top: 8rem;
                    width: 85%;
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    background-color: #ffffff0d;
                    border-radius: 5px;

                    &.menu-account-block {
                        margin-top: 12rem;
                        img {
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                        .flex-between {

                            svg {
                                margin: auto 0;
                            }
                        }
                    }

                    &:nth-of-type(2), &:nth-of-type(3) {
                        &:before {
                            content: "";
                            border-top: 1px solid grey;
                            width: 100%;
                            position: absolute;
                            top: -4rem;
                        }
                    }

                    .lang-closed {
                        padding-bottom: 0;

                        .lang-menu {
                            #arrow-menu {
                                cursor: pointer;
                                float: right;
                                transition: transform .2s;
                            }

                            .language-sub {
                                height: 0;
                                overflow: hidden;
                                margin-top: 2rem;
                            }
                        }
                    }

                    .lang-open {
                        padding-bottom: 0;

                        .lang-menu {
                            #arrow-menu {
                                float: right;
                                transform: rotate(90deg);
                            }

                            .language-sub {
                                margin-top: 1rem;
                                width: 100%;
                                margin-left: auto;
                                margin-right: auto;
                                position: relative;
                                background-color: transparent;
                                border-style: none;

                                li {
                                    cursor: pointer;
                                    padding: 2rem 0;
                                    font-size: 1.6rem;
                                    border-style: none;

                                    img {
                                        margin-right: 10px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }

                    a {
                        &:not(:last-child) {
                            li {
                                border-bottom: 1px solid grey;
                            }
                        }
                    }

                    li {
                        svg {
                            margin-right: 10px;
                            height: 14px;
                        }

                        cursor: pointer;
                        padding: 2rem 2rem;
                        font-size: 1.6rem;

                        &:not(:last-child) {
                            border-bottom: 1px solid grey;
                        }
                    }
                }

                .logo {
                    display: block;
                    position: absolute;
                    top: .5rem;
                    left: 1rem;
                    z-index: 9;
                }

            }

            .socials {
                width: 85%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 4rem;

                svg:not(:first-child) {
                    padding-left: 7px;
                }
            }

            .copyright {
                width: 85%;
                margin-top: 2rem;
                margin-left: auto;
                margin-right: auto;

                p {
                    font-size: 1rem;
                    line-height: 1.7rem;
                    font-weight: 300;
                }
            }
        }
    }
}
