.categories-page {
    .discipline-title {
        margin: auto auto 5rem;
        position: relative;
        &:before {
            content: '';
            background: linear-gradient(180deg, rgba(10, 0, 51, 0.26) 0%, rgba(10, 0, 51, 0) 100%);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .title {
            padding: 15rem 8rem 5rem;
            position: relative;
            display: flex;
            h1 {
                span {
                    cursor: pointer;
                    &:before {
                        content: url("../images/button_round.svg");
                        display: block;
                        width: 40px;
                        height: 40px;
                        margin-right: 20px;
                    }
                    &:hover {
                        display: block;
                        transform: translate(1px, 2px);	
                        content: url("../images/button_round_hover.svg");
                        width: 40px;
						height: 40px;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }
                display: flex;
                z-index: 2;
                font-weight: 600;
                font-size: 4rem;
            }
        }
        svg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }

    .categories {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 25px;
        margin: 0 12% 7rem;

        .category {
            display: grid;
            align-self: center;
            justify-self: center;
            width: 100%;
            position: relative;
            cursor: default;

            .image_container {
                overflow: hidden;
                img {
                    height:auto;
                    width: 100%;
                    overflow: hidden;
                    transition: 0.5s all ease-in-out;
                }
            }

            .title {
                position: absolute;
                bottom: 0;
                height: 40%;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.8rem;
                color: white !important;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                transition: height ease 0.5s;

                h4 {
                    text-transform: capitalize;
                    font-weight: 600;
                    font-size: 2rem;
                    position: relative;
                    margin-left: 3rem;
                    margin-bottom: 2rem;

                    &:before {
                        content: '';
                        position: absolute;
                        left: -1rem;
                        right: 0;
                        height: 1.8rem;
                        width: 3px;
                        background: #9C90FA;
                    }
                }
            }


            .play-button{
                height: 0;
				overflow: hidden;
                position: absolute;
                z-index: 9;
            }

            &:hover {
                .title {
                    height: 100%;
                }
                .image_container {
                    img{
                        transform: scale(1.1);
                    }
                }

                .play-button {
                    height: auto;
                    bottom: -1.5rem;
                    right: 1rem;


                    .btn{
                        cursor: pointer;
                        &:hover {
                            .play_sign {
                                fill: #362CAE;
                            }
                            path{
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $mobile) {
    .categories-page {
        .discipline-title {
            margin: auto auto 3rem !important;
            .title {
                padding: 9rem 1rem 2rem !important;
                h1 {
                    font-size: 5vw !important;
                    line-height: 40px;
                }
            }
            svg {
                display: none;
            }
        }
        .categories {
            grid-template-columns: 1fr;
            margin: 1rem 2rem;
        }
    }
}