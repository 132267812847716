.toast-modal {
    display: flex;
    position: fixed;
    z-index: 99;
    width: 220px;
    max-width: 100vw;
    height: 48px;
    top: 9.5rem;
    right: -250px;
    background: #FFFFFF;
    border-radius: 5px 0 0 5px;
    color: #000000;
    transition: all ease 0.5s;
    padding: 1rem;
    &.display {
        opacity: 1;
        right: 0;
    }
    &.success {
        border-left: 10px solid #1FC911;
    }
    &.error {
        border-left: 10px solid #F23C27;
    }
    .title {
        margin: auto;
    }
}