.preference {
	&.block {
		p {
			display: block;
			margin-top: 2rem;
		}
		form {
			width: 50%;
		}

	}
}

@media (max-width: $mobile) {
	form {
		width: 100%;
	}
}