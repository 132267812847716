#fullscreen_background {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: black;
	top: 0;
	z-index: 9;
}

.player-page {
	padding-top: 16rem;
	padding-bottom: 2rem;

	// Sass for videojs-fullscreen-iphone

	.vjs-icon-fullscreen-enter:before {
		content: "\f108";
	}
	.vjs-icon-fullscreen-enter{
		font-family: VideoJS;
		font-weight: normal;
		font-style: normal;
	}
	.vjs-icon-fullscreen-exit:before{
		content: "\f109";
	}
	.vjs-fullscreen-iphone-on{
		position: fixed !important;
		z-index: 99;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.video-js {
		.vjs-fullscreen-control{
			display: none;
		}

		.vjs-fullscreen-iphone-control {
			cursor: pointer;
			flex: none;

			.vjs-icon-placeholder {
				@extend .vjs-icon-fullscreen-enter;
			}
			.vjs-icon-placeholder:before {
				@extend .vjs-icon-fullscreen-enter:before;
			}
		}

		&.vjs-fullscreen-iphone-video{
			.vjs-fullscreen-iphone-control {
				.vjs-icon-placeholder:before {
					@extend .vjs-icon-fullscreen-exit:before;
				}
			}
		}
		&.vjs-fullscreen-iphone-video.vjs-fluid{
			height: 100% !important;
		}
	}
	.d-flex {
		position: relative;
	}
	.back {
		cursor: pointer;
		z-index: 8;
		&:before {
			position: absolute;
			top: 4rem;
			left: 4rem;
			content: url("../images/button_round.svg");
			display: block;
			width: 40px;
			height: 40px;
			margin-right: 20px;
			z-index: 99;
		}
		&:hover {
			&:before {
				transform: translate(1px, 1px);	
				content: url("../images/button_round_hover.svg");
			}
		}
	}
	.video-iframe {
		width: 100%;
		height: 100%;
		border: 1px solid rgba(255, 255, 255, 0.14);
		display: grid;
		grid-template-columns: 60% 40%;

		.poster-live {
			img {
				height: auto;
				width: 100%;
			}
		}
		.stripe-info-block {
			@include center(true, true);
			font-size: 1.6rem;
			background-color: #0F347F;
			border-radius: 5px;
			width: 55%;
			min-width: 2rem;
			box-shadow: 0 0 20px -4px #0f347f;

			.stripe-tabs {
				border: 1px;
				border-style: none none solid none;
				:nth-child(1) {
					border-radius: 5px 0 0 0;
				}
				:nth-child(2) {
					border-radius: 0 5px 0 0;
				}
				> div {
					cursor: pointer;
					padding: 1.5rem 0;
					text-align: center;
					width: 50%;
					&.active {
						background: rgba(255, 255, 255, 0.12);
					}
				}
			}
			.stripe-content {
				padding: 1rem 2rem 4rem;

				.element {
					margin-top: 1rem;
					background-color: white;
					color: #667085;

					.checkbox {
						margin: auto 1.5rem;
						.d-block {
							div {
								display: block !important;
							}
						}
						label {
							padding: 0;
						}
					}
					.content {
						margin: auto 1.5rem;
						h2 {
							font-weight: 600;
							font-size: 1.8rem;
						}
					}
				}

				.StripeElement {
					display: block;
					margin: 10px 0 20px 0;
					max-width: 500px;
					padding: 10px 14px;
					border-radius: 4px;
					background: hsla(0,0%,100%,.12);
				}
			}

		}

		.video-js {
			width: 100%;
			aspect-ratio: 16 / 9;
			max-height: 900px;
			position: relative;
			overflow: hidden;

			.vjs-big-play-button {
				width: 2.5em;
				height: 2.5em;
				border-radius: 50%;
				border: none;
				background-color: #362CAE;
				top: calc(50% - 3rem);
				left: calc(50% - 3rem);
				transition: ease-in-out all .5s;

				.vjs-icon-placeholder:before {
					font-size: 5rem;
					top: 20%;
					left: 2%;
					color: $white;
					transition: ease all .5s;
				}

				&:hover {
					border: $white;
					background-color: $white;

					.vjs-icon-placeholder:before {
						color: #362CAE;
					}
				}

			}
			.vjs-menu {
				z-index: 9;
			}
			.vjs-menu-item {
				text-transform: uppercase;
			}
			.vjs-resolution-button-label {
				position: absolute;
				left: 7px;
				top: 10px;
			}
			&.vjs-paused {
				.vjs-big-play-button {
					display: block !important;
				}
			}

			//PLUGINS
			//watermark
			&.vjs-watermark {
				display: block;
			}
			.vjs-watermark-content {
				opacity: 0.99;
				height: 10%;
				position: absolute;
				padding: 5px;
				@include transition(visibility 1.0s, opacity 1.0s);
				img {
					height: 100%;
					width: auto;
				}
			}

			&.vjs-user-inactive {
				.vjs-watermark-bottom-right, .vjs-watermark-bottom-left {
					bottom: 10px;
				}
			}

			.vjs-watermark-top-right {
				transition:all 1s linear;
				right: 10px;
				top: 10px;
			}
			.vjs-watermark-top-left {
				transition:all 1s linear;
				left: 10px;
				top: 10px;
			}
			.vjs-watermark-bottom-right {
				transition:all 1s linear;
				right: 10px;
				bottom: 40px;
			}
			.vjs-watermark-bottom-left {
				transition:all 1s linear;
				left: 10px;
				bottom: 40px;
			}

			&.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
				opacity: 0;
			}

			//Tablet
			&.vjs-user-inactive {
				.vjs-pills-content {
					bottom: 10px;
				}
			}
			.vjs-pills-content {
				transition:all 1s linear;
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				width: 60%;
				height: 14%;
				left: 0;
				right: 0;
				bottom: 40px;

				a {
					display: block;
					height: 100%;
					width: 100%;
					position: relative;
				}

				img {
					height: 100%;
					width: auto;
					display: block;
					margin: auto;
				}
			}
			//sponsors
			.vjs-sponsors-content {
				z-index: 9;
				position: relative;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(45deg, #2E87FF 0%, #4828DC 29%, #394e6b 66%, #2E87FF 100%);
				background-size: 400% 400%;
				animation: Gradient 15s ease infinite;
				overflow: hidden;
				text-align: center;
				.center {
					height: 100%;
					display: flex;
					justify-content: space-evenly;
					align-items: center;
					flex-direction: column;
					z-index: 1;
					position: relative;
					.heading {
						h2, h3 {
							text-transform: uppercase;
							margin-bottom: 0.5rem;
						}
						h2 {
							font-size: 4rem;
						}
						h3 {
							font-size: 3rem;
						}
					}
				}

				.logos {
					position: absolute;
					width: 15%;
					z-index: 9;
					padding: 10px;
				}

				.background {
					z-index: 1;
					position: absolute;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					mix-blend-mode: multiply;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}

				&:before, &:after {
					content: "";
					width: 70vmax;
					height: 70vmax;
					position: absolute;
					background: rgba(255, 255, 255, .07);
					left: -20vmin;
					top: -20vmin;
					animation: morph 15s linear infinite alternate, spin 20s linear infinite;
					z-index: 1;
					will-change: border-radius, transform;
					transform-origin: 55% 55%;
					pointer-events: none
				}
				&:after {
					width: 70vmin;
					height: 70vmin;
					left: auto;
					right: -10vmin;
					top: auto;
					bottom: 0;
					animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
					transform-origin: 20% 20%
				}
				.sp1, .sp2, .sp3 {
					display: flex;
					width: 60%;
					max-height: 25%;
					.wrp {
						width: 100%;
						background-color: #fff;
						padding: 2%;
						border-radius: 5px;
						margin: 1%;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							object-fit: contain;
							max-height: 100%;
							max-width: 100%;
						}
					}
				}
			}
			//toasts
			.vjs-toasts-content {
				position: absolute;
				top: 10px;
				right: 10px;
				display: inline-flex;
				max-width: 80%;
				.toasts-primary, .toasts-secondary {
					width: 50%;
					display: flex;
				}
				img {
					padding: 1%;
					max-height: 200px;
					height: auto;
					float: left;
					flex: 0 0 auto;
					margin: auto;
				}
			}
			//pre roll
			.ads-mute {
				z-index: 10;
				position: absolute;
				border: 2px solid #2e87ff;
				border-radius: 2px;
				padding: 10px 15px;
				left: 10px;
				top: 10px;
				font-size: 2rem;
			}
		}
	}
	.video-data {
		background-color: #0F347F;
		padding: 3rem 4rem;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: auto auto auto;
		align-items: start;

		.first-row {
			width: 100%;
			h2 {
				font-weight: bolder;
				font-size: 2.5rem;
				line-height: 3rem;
				text-transform: capitalize;
			}
		}

		.views-block {
			display: flex;
			margin-top: 20px;
			font-size: 1.7rem;
			.views {
				color: #c5c5c5;
			}
			span {
				margin-right: 10px;
			}

			:not(.start-date) {
				time {
					&:before {
						content:"\A";
						width:10px;
						height:10px;
						border-radius:50%;
						background: #c5c5c5;
						display:inline-block;
						margin-right: 10px;
					}
				}
			}

			.stars {
				float: left;
				padding-right: 2rem;
				margin-top: auto;
				margin-bottom: auto;
				label {
					float: right;
					width: 1.9rem;
					height: 1.9rem;
					overflow: hidden;
					white-space: nowrap;
					cursor: pointer;
					margin-right: 0.7rem;
				}
				&:not(:checked) > input {
					position: absolute;
					top: -9999px;
				}
				> label:before {
					width: 1.9rem;
					height: 1.9rem;
				}
				&:not(:checked) > label:before {
					content: url("../images/star_transparent.svg");

				}

				&:not(:checked) > label:hover,
				input:checked + label:before {
					content: url("../images/star_fill.svg");
				}
				&:not(:checked) > label:hover,
				&:not(:checked) > label:hover ~ label {
					content: url("../images/star_fill.svg");
				}
				> input:checked + label:hover,
				> input:checked + label:hover ~ label,
				> input:checked ~ label:hover,
				> input:checked ~ label:hover ~ label,
				> label:hover ~ input:checked ~ label {
					content: url("../images/star_fill.svg");
				}
			}
		}
		#share-live
		{
			justify-content: flex-start;
		}
		.buttons {
			display: flex;
			position: relative;
			font-size: 1.6rem;
			margin-top: 2rem;
			justify-content: space-evenly;

			.plus, .minus {
				padding: 11px 15px;
				font-weight: 300;
				&:before {
					margin-right: 5px;
					width: 2rem;
				}
			}
			.plus {
				&:before {
					content: '+ ';
				}
			}
			.minus {
				&:before {
					content: '- ';
				}
			}
			.shared {
				padding: 9px 15px;
				font-weight: 300;
				transition: transform ease 300ms;
				&:before {
					content: url('data:image/svg+xml,<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 12.1667C11.9062 12.1667 11.3583 12.375 10.9292 12.7229L6.6125 9.60001C6.68478 9.20328 6.68478 8.79675 6.6125 8.40001L10.9292 5.27709C11.3583 5.62501 11.9062 5.83334 12.5 5.83334C13.8792 5.83334 15 4.71251 15 3.33334C15 1.95418 13.8792 0.833344 12.5 0.833344C11.1208 0.833344 10 1.95418 10 3.33334C10 3.57501 10.0333 3.80626 10.0979 4.02709L5.99792 6.99584C5.38958 6.18959 4.42292 5.66668 3.33333 5.66668C1.49167 5.66668 0 7.15834 0 9.00001C0 10.8417 1.49167 12.3333 3.33333 12.3333C4.42292 12.3333 5.38958 11.8104 5.99792 11.0042L10.0979 13.9729C10.0333 14.1938 10 14.4271 10 14.6667C10 16.0458 11.1208 17.1667 12.5 17.1667C13.8792 17.1667 15 16.0458 15 14.6667C15 13.2875 13.8792 12.1667 12.5 12.1667ZM12.5 2.25001C13.0979 2.25001 13.5833 2.73543 13.5833 3.33334C13.5833 3.93126 13.0979 4.41668 12.5 4.41668C11.9021 4.41668 11.4167 3.93126 11.4167 3.33334C11.4167 2.73543 11.9021 2.25001 12.5 2.25001ZM3.33333 10.8333C2.32292 10.8333 1.5 10.0104 1.5 9.00001C1.5 7.98959 2.32292 7.16668 3.33333 7.16668C4.34375 7.16668 5.16667 7.98959 5.16667 9.00001C5.16667 10.0104 4.34375 10.8333 3.33333 10.8333ZM12.5 15.75C11.9021 15.75 11.4167 15.2646 11.4167 14.6667C11.4167 14.0688 11.9021 13.5833 12.5 13.5833C13.0979 13.5833 13.5833 14.0688 13.5833 14.6667C13.5833 15.2646 13.0979 15.75 12.5 15.75Z" fill="white"/></svg>');
					margin-right: 8px;
					width: 1.5rem;
					vertical-align: middle;	
					line-height: 1;		
				}
				#arrow-menu {
					margin-left: 1rem;
				}
			}
			.share-closed{
				ul {
					height: 0;
					overflow: hidden;
				}
			}

			.share-open {
				position: relative;
				#share_btn{
					border-radius: 20px 20px 0 0;
				}
				#arrow-menu {
					transform: rotate(90deg);
				}
				ul {
					background-color: #204288;
					border-radius: 0 0 20px 20px;
					padding: 0;
					margin: 0;
					font-size: 14px;
					position: absolute;
					width: 100%;
				}
				li {
					padding: 6px;
					a {
						display: flex;
						justify-content: center;
						font-weight: 600;
					}
					&:hover {
						background-color: rgba(255, 255, 255, 0.2);
						transition: background-color 200ms ease-in-out;
					}
					&:last-child:hover {
						border-radius: 0 0 20px 20px;
						background-color: rgba(255, 255, 255, 0.2);
						transition: background-color 200ms ease-in-out;
					}
					&:not(:last-child) {
						padding: 9px;
					}
					.copy-link {
						background-color: transparent;
						border: none;
						color:white;
						font-weight: 600;
						position: relative;
						right: 2rem;
					}
				}

			}

			.button.button-blue{
				background-color: rgba(255, 255, 255, 0.07);
				transition: 0.2s all ease-in-out;
				height: fit-content;

			}

			.button.button-blue:hover{
				background-color: rgba(255, 255, 255, 0.2);
				transition: background-color 200ms ease-in-out;
				
			}

			.button:not(:first-child) {
				margin-left: 2rem;
			}

			ul {
				text-align: right;
				svg {
					padding-right: 5px;
				}
			}

		}

		.description {
			margin-top: 2rem;
			font-size: 1.6rem;
			line-height: 2.5rem;
		}

		p {
			text-transform: uppercase;
			font-weight: lighter;
			font-size: 2rem;
		}
	}

	.similar-video-container {
		.titles, .slick-slider {
			width: 100%;
			max-width: 100%;
		}
		.video {
			cursor: pointer;
			position: relative;
			width: 100%;
			margin-right: 10px;

			a {
				color: white;
				text-decoration: none;

				&:before {
					//filter color
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					opacity: 0;
					background: rgba(31, 26, 86, 0.58);
					transition: all ease 0.5s;
				}

				> .image {
					display: block;
					height: auto;
					width: 100%;
					border-radius: 5px 5px 0 0 ;
				}


				.title-container {
					background-color: #191549;
					position: absolute;
					bottom: -2px;
					text-transform: capitalize;
					font-size: 1.8rem;
					padding: 0.8rem 1rem 2rem;
					height: 82px;
					left: 0;
					right: 0;
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
					transition: all ease 0.5s;

					.play {
						position: absolute;
						width: 5rem;
						height: 5rem;
						top: -2.5rem;
						right: 1rem;
						opacity: 0;
						transition: all ease 0.5s;

						.play_button {
							height: 100%;
							width: 100%;
							cursor: pointer;

							&:hover {
								.play_sign{
									fill: #362CAE;
								}
								path{
									fill: white;
								}
							}
						}
					}

					.video-date {
						display: grid;
						grid-template-columns: 33.33% 33.33% 33.33%;
						font-size: 10px;
						line-height: 12px;
						padding-bottom: 5px;

						.line {
							svg{
								width: 100%;
								vertical-align: middle;
							}
						}

						.date {
							text-align: center;
						}
					}
					h4 {
						font-size: 14px;
						line-height: 17px;
					}

					.video-description {
						margin-top: 0.5rem;
						font-size: 1rem;
						line-height: 1.4rem;
						height: 0;
						overflow: hidden;
						transition: height ease 0.5s;
					}
				}
			}

			&:hover {
				a:before {
					opacity: 1 !important;
				}
				.video-description {
					height: auto !important;
				}
				.play {
					opacity: 1 !important;
				}
				.title-container {
					height: 40% !important;
				}
			}
		}
	}
}

@media (max-width: $mobile) {
	.player-page {
		padding-top: 7rem;
		width: 100%;
		.back:before {
			top: 2rem;
			left: 2rem;
			z-index: 14;
		}
		.video-iframe {
			grid-template-columns: 1fr;
			.video-js {
				.vjs-sponsors-content {
					.center {
						.heading {
							h2 {
								font-size: 2.2rem;
							}
							h3 {
								font-size: 2rem;
							}
						}
					}
				}
			}
		}
		.video-data {
			padding: 2rem 2rem 0 2rem;
			border-bottom: unset;
			.first-row {
				h2 {
					font-size: 2rem;
				}
			}
			.buttons {
				margin: 2rem 0;
				justify-content: space-between;
				display: flex;
				button {
					margin: unset !important;
					background: rgba(255, 255, 255, 0.07);
				}
			}
			.views-block {
				font-size: 1.5rem;
				.stars label {
					margin-right: 0.4rem;
				}
			}
		}

		.similar-video-container {
			padding: 15px;
			margin-bottom: -2.5rem;
			h3 {
				font-size: 2rem;
				padding-bottom: 2rem;
			}
			.content {
				display: grid;
				grid-gap: 15px;
				grid-auto-flow: dense;
				grid-template-columns: repeat(2, auto);
				.video {
					position: relative;
					.title-container {
						padding: .4rem .5rem 1rem;
						h4 {
							font-size: 12px;
						}
					}
				}
			}
		}

	}
}
